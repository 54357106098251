/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.grid-items .grid-item article .entry-summary').matchHeight();

        $('select#PropertyCounty').change(function() {
          window.location = "/building-communities/current-properties/?countyID="+$(this).val() ;
        });
        $('select#PropertyCity').change(function() {
          window.location = "/building-communities/current-properties/?cityID="+$(this).val() ;
        });
        $('select#PropertyName').change(function() {
          window.location = "/building-communities/current-properties/detail/?propertyID="+$(this).val() ;
        });


        /****************
        **  Slick carousels
        *****************/
        $('.slideshow-gallery').slick({
          dots: true,
          infinite: true,
          autoplay: false,
          rows: 0,
          prevArrow:"<img class='a-left control-c prev slick-prev' src='/wp-content/themes/housing/dist/images/arrow-left.png'>",
          nextArrow:"<img class='a-right control-c next slick-next' src='/wp-content/themes/housing/dist/images/arrow-right.png'>",
        });
        $('.slideshow-gallery').slickLightbox();

        $('.properties-tiles').slick({
          dots: false,
          infinite: true,
          rows: 0,
          autoplay: true,
          autoplaySpeed: 5000,
          prevArrow:"<img class='a-left control-c prev slick-prev' src='/wp-content/themes/housing/dist/images/arrow-left.png'>",
          nextArrow:"<img class='a-right control-c next slick-next' src='/wp-content/themes/housing/dist/images/arrow-right.png'>",
          speed: 400,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        });

        $('.block-team .headshot article').matchHeight();

        $('.block-team .headshot').on('click', function (e) {
          var teamTarget = $(this).data('target');
          if ($(this).hasClass('active')) {
            $('.block-team .headshot').removeClass('active');
            $('.block-team .detail').removeClass('active');
          } else {
            $('.block-team .headshot').removeClass('active');
            $('.block-team .detail').removeClass('active');
            $(this).addClass('active');
            $("#"+teamTarget).addClass('active');
          }
          return false;
        });
        $('.block-team .team-close').on('click', function (e) {
          $('.block-team .headshot').removeClass('active');
          $('.block-team .detail').removeClass('active');
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // CURRENT PROPERTIES page
    'current_properties': {
      init: function() {
        var breadcrumbTitle = $('.page-header h1.entry-title span').text();
        $('#breadcrumbs >span >span >span >span').removeClass('breadcrumb_last');
        $('#breadcrumbs >span >span >span >span').addClass('breadcrumblast');
        $('#breadcrumbs >span >span >span').append( ' &nbsp;|&nbsp; <span class="breadcrumb_last">'+breadcrumbTitle+'</span>' );
      }
    },
    // PROPERTY DETAIL page
    'page_id_516': {
      init: function() {
        var breadcrumbTitle = $('.page-header h1.entry-title span').text();
        var breadcrumbCountyName = $('span.county-info span.county-name').text();
        var breadcrumbCountyURL = $('.more-properties .county-link').attr('href');
        $('#breadcrumbs >span >span >span >span >a').after( ' &nbsp;|&nbsp; <a href="'+breadcrumbCountyURL+'">'+breadcrumbCountyName+'</span>' );
        $('#breadcrumbs >span >span >span >span >span').text(breadcrumbTitle);
      }
    },
    // Archive page
    'archive': {
      init: function() {
        if ( $( "body" ).hasClass( "paged" ) ) {
          $('#breadcrumbs >span >span >a').after( ' &nbsp;|&nbsp; <a href="/news/">News &amp; Ideas</a>' );
        } else {
          $('#breadcrumbs .breadcrumb_last').before( '<a href="/news/">News &amp; Ideas</a> &nbsp;|&nbsp; ' );
        }
      }
    },
     // ERV Project
    'single_project': {
      init: function() {
        $('#breadcrumbs .breadcrumb_last').before( '<a href="/financing-and-investing/">Financing and Investing</a> &nbsp;|&nbsp; <a href="/financing-and-investing/evernorth-rural-ventures/">Evernoth Rural Ventures</a> &nbsp;|&nbsp; <a href="/financing-and-investing/evernorth-rural-ventures/projects/">ERV Projects</a> &nbsp;|&nbsp;' );
      }
    },
    // Blog entry
    'single_post': {
      init: function() {
        $('#breadcrumbs .breadcrumb_last').before( '<a href="/news/">News &amp; Ideas</a> &nbsp;|&nbsp; ' );
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
